import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { GetUserForm } from '../../services/srvc-utilities'
import { GetTemplateList, CreateTask } from '../../services/srvc-invoices-realm'
import { GetLocalBusiness, GetLocalUser } from '../../services/srvc-auth-user'
import WebbLoaderSmall from '../webx/webb-loader-sm'



export const InvoiceCreateModule = () => {

const usxx = GetUserForm()
const usrx = GetLocalUser()
const temx = GetLocalBusiness()
const asset = usxx === 'user' ? usrx : temx

  const [data, setData] = useState([])
  const [loading, setLoading] = useState(true)
  const [memo, setMemo] = useState('')
  const [DynamicFieldsArr,setDynamicFieldsArr]= useState([])
  const [hide, setHide] = useState(false)
  const [refresh, setRefresh] = useState(false)

  

  var dynamicFieldsArr=[]
  var AllkeysArr=[]
  var finalArr=[]
  var signerlist=[]

  const navigate = useNavigate()

  useEffect(()=>{
    if(asset)
    {
    setLoading(true)
    const fetchdata = async()=>{

      var res = (await GetTemplateList({data:{}})).data
    //   console.log(res.data[0])
      setData(res.data[0])
      setLoading(false)
    }
    fetchdata()
  }

  },[refresh])



  const HandleFormInput = async(key,value) => {

    // console.log(key, value)
    var indx=key
    var obj={ type:indx }
    obj[indx]=value
    // console.log(obj)
    dynamicFieldsArr.push(obj);
    setDynamicFieldsArr([...DynamicFieldsArr,...dynamicFieldsArr])
  }


  const handleChange = async(key, val) => {
    setData({ ...data, [key]: val });
  }


  const handleSubmit = async () => {
    
    setLoading(true); 

    // console.log(DynamicFieldsArr)
    DynamicFieldsArr.map(item=>{
      var keys= Object.keys(item)
      AllkeysArr.push(keys[1])
    })
 
    // console.log(AllkeysArr)
     
    var uniqueKeysArr=AllkeysArr.filter((x,i,a)=>a.indexOf(x)==i)
    // console.log(uniqueKeysArr)
    for(var i=0;i<uniqueKeysArr.length;i++) {
      var result=  DynamicFieldsArr.filter((x)=>x.type === uniqueKeysArr[i]);
      finalArr.push(result[result.length-1])
    }
    // console.log(finalArr)
     
    var obj1={}
    for(var i=0;i<finalArr.length;i++) {
      obj1={...obj1,...finalArr[i]}
    }
 
      // console.log(obj1)
    var listArr=[]
    listArr.push(obj1)
    
    var datx = {
        "list": listArr,
        "user": {item:asset.item, name: asset.name, mail: asset.mail}
    }
    // console.log(datx)
    
    // console.log(body)
    const res = await CreateTask({data: datx, user: asset.item})
    // console.log (res)
    if(res.data.code==201)
    { 
      setTimeout(()=>{
        setHide(true)
        setLoading(false)
        setMemo("Invoice Created SuccessFully, It will reflect within few seconds on dashboard (Home)")
     
      }, 4000)

      setTimeout(()=>{navigate("/user/home")},6000)
      

    }
    //window.alert("Congratulations!! Your Request for Template Preparation has been initiated")

    // history.push(`/${asset.form.substring(0,2)}/smart/view/${info.smart_id}`) // check this route
    // setLoading(false)

  }

  


  if(loading) return(<div><WebbLoaderSmall /></div>)


  return (
    <>
    
    <div className={` ${hide?'d-none':'border p-3 rounded mt-3 bg-white'} `}>
      <div className={`scroll p-3  ${data.vars.length>0?'':'d-none'}`} style={{height:"450px", overflowY:"scroll"}}> 
      {
      data!=null && data.vars.length>0 && data.vars.map((item,i) => {
        return(
          <div key={i}>
            <p className="mb-1">{item.name}</p>
            <input className='form-control height-md rounded-none'
              onChange={(e)=>HandleFormInput(item.code,e.target.value)} 
              type="text" 
              placeholder={'...'}/>
            <br/>
         </div>
        )
      })
      }
      <div className={data && data.vars.length==0?'':'d-none'}>
        <p className="text-center text-warning">Sorry! We could not found dynamic fields in this template.</p>

      </div>
      </div>
      <button onClick={()=>handleSubmit()} className='btn btn-primary w-100 mt-3'>Submit</button>
      
    </div>
    <div className={hide?'':'d-none'}>
    <p className='text-center fw-bold text-success mt-5'>{memo}</p>
    </div>
    </>
  )
}
