import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { GetUserForm } from '../../services/srvc-utilities'
import { GetLocalBusiness, GetLocalUser } from '../../services/srvc-auth-user'
import { ApproveInvoice, GetInvoiceDetails, InvoicePaymentSlip, SendReminder } from '../../services/srvc-invoices-realm'
import WebbLoaderMedium from '../webx/webb-loader-md'
import WebbDividerMedium from '../webx/webb-divider-md'


export const InvoiceViewModule = () => {

const navigate = useNavigate()
const usxx = GetUserForm()
const usrx = GetLocalUser()
const temx = GetLocalBusiness()
const asset = usxx === 'user' ? usrx : temx

const [loading, setLoading] = useState(true)
const [data, setData] = useState()
const [paylist, setPaylist] = useState([])
const [reminder, setReminder] = useState(false)
const [memo, setMemo] = useState('')
const [refresh, setRefresh] = useState(false)


const {id} = useParams()


useEffect(()=>{

  if(asset)
  {
    setLoading(true)
    const fetchdata = async()=>{
        var payments = (await InvoicePaymentSlip({data:{item:id}})).data
        // console.log(payments.data.list)
        setPaylist(payments.data.list)
        var res = (await GetInvoiceDetails({data:{item:id}})).data
        console.log(res)
        setData(res)
        setLoading(false)
    }
    fetchdata()
  } 

},[refresh])


const ReminderForInvoice = async()=>{
    setReminder(true)
    const res = await SendReminder({data:{item:id}})
    // console.log(res)
    setMemo("Reminder has been sent.")
    setTimeout(()=>{setMemo('')},2000)
    setReminder(false)

}

const InvoiceApprove = async()=>{
    setLoading(true)
    var res = await ApproveInvoice({data:{item:id}})
    setRefresh(!refresh)
    setLoading(false)

}


if(loading) return(<div><WebbLoaderMedium /></div>)

  return (
    <div className=''>
    <h5 className='fw-bold text-secondary mb-3'>Invoice Details </h5>
    <div className='border p-3 rounded text-small mb-4 bg-white '>
        <div className='d-flex'>
            <div className='me-auto'>Invoice Number: </div>
            <div className="text-end">{data.info.invoice_no}</div>
        </div>

        <div className='d-flex mt-2'>
            <div className='me-auto'>Company Name: </div>
            <div className="text-end">{data.info.company_name}</div>
        </div>

        <div className='d-flex mt-2'>
            <div className='me-auto'>GST Number: </div>
            <div className="text-end">{data.info.buyer_gst}</div>
        </div>

        <div className='d-flex mt-2'>
            <div className='me-auto'>Download Invoice File: </div>
            <div onClick={()=>window.open(data.file.link)} className="text-end btn-link" style={{cursor:'pointer'}}>
               click here
            </div>
        </div>

        <div className='d-flex mt-2'>
            <div className='me-auto'>Create At: </div>
            <div className="text-end">{new Date(data.crts).toLocaleDateString()}, {new Date(data.crts).toLocaleTimeString()}</div>
        </div>

        <div className='d-flex mt-2'>
            <div className='me-auto'>Status: </div>
            <div className="text-end">{data.stat=="0"? 'draft': data.stat=="6"? 'approved':'In Process'}</div>
        </div>
       

    </div>
    

    <div className={` ${data.stat!= "6"?'d-flex justify-content-start':'d-none'} `}>
        <div><button onClick={()=> InvoiceApprove()} className='btn btn-success rounded-xx'> Approve</button> </div>
        <div className='mx-2'></div>
        <div><button disabled={reminder?true:false} onClick={()=>ReminderForInvoice()} className='btn btn-outline-primary rounded-xx text-small'>{reminder?'Sending ...':'Reminder'}</button></div>
        <div className='mx-2'></div>
        <div><button className='btn btn-outline-danger rounded-xx d-none'>Cancel</button></div>
        <div className='mx-2'></div>
        <div><p className='text-warning mt-2'>{memo}</p></div>
        
    </div>
    
    <WebbDividerMedium />
    

    <h5 className='fw-bold text-secondary mt-3 mb-3'>Payments Details </h5>
    <div>
        {
            paylist && paylist.map((item,i)=>{
                return(
                    <div className='p-2 w-100 border rounded mt-2 bg-white'>
                        {/* <div className='d-flex m-0 px-3'>
                            <div className='me-auto small'>Paid By</div>
                            <div className='text-end m-0 small '>{item.user.name}</div>
                        </div>
                        <div className='d-flex px-3 m-0 '>
                            <div className='me-auto small'>Company</div>
                            <div className='text-end small'>{item.user.comp}</div>
                        </div> */}
                        <div className='d-flex px-3 '>
                            <div className='me-auto small'>
                                <p className='m-0'>Payment slip</p>
                                <p className='m-0'>Date {new Date(item.crts).toLocaleDateString()}, {new Date(item.crts).toLocaleTimeString()} </p>
                            </div>
                            <div className='text-end'>
                            <div onClick={()=>window.open(item.file.link)} className="text-end btn-link" style={{cursor:'pointer'}}>
                               click here
                            </div>
                            </div>
                        </div>
                        
                    </div>
                )
            })
        }
    </div>

    </div>
  )
}
