import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { GetLocalBusiness, GetLocalUser } from '../../services/srvc-auth-user';
import { GetDashboardStat, GetInvoiceList } from '../../services/srvc-invoices-realm';
import WebbLoaderSmall from '../webx/webb-loader-sm';

export const InvoiceStatistics = (props) => {

const navigate = useNavigate();
const asset = GetLocalUser()
const team = GetLocalBusiness()

const [ refresh, setRefresh] = useState(false)
const [loading, setLoading] = useState(true)
const [statistics, setStatics] = useState()

console.log(asset)

useEffect(()=>{
if(asset)
    {

    setLoading(true)
    const fetchdata = async()=>{
      var stat = (await GetDashboardStat({data:{user:asset.item}})).data
      setStatics(stat.data)
      setLoading(false)
    }
    fetchdata()
  }

},[props.refresh])

if(loading) return(<><WebbLoaderSmall /></>)

  return (
    <div className=''>
        <div className='d-flex mt-4'>
          
          <div className='p-3 border rounded bg-white' style={{width:'200px'}}>
            <h5 className='fw-bold text-secondary text-center'>Total Invoices</h5>
            <h4 className='fw-bold text-secondary text-center'>{statistics.totalinvoice}</h4>
          </div>

          <div className='p-3 border rounded mx-2 bg-white' style={{width:'200px'}}>
            <h5 className='fw-bold text-secondary text-center'>Draft Invoices</h5>
            <h4 className='fw-bold text-secondary text-center'>{statistics.draftinvoice}</h4>
          </div>

          <div className='d-none p-3 border rounded bg-white' style={{width:'200px'}}>
            <h5 className='fw-bold text-secondary text-center'>Total Payment</h5>
            <h4 className='fw-bold text-secondary text-center'>{statistics.totalpayment}</h4>
          </div>
        </div>

      </div>
  )
}
