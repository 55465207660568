// routes
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import { useAuth } from "../context/authcontext";

// views - main
import Main from "../views.xz/main-xz";

// views - assets

// views - home
import HomeUser from "../views.home/home-user";

// views - invoices
import InvoicesList from "../views.invoices/invoices-list";
import InvoicesView from "../views.invoices/invoices-view";
import InvoicesCreate from "../views.invoices/invoices-create";


// views - transfers
import TransfersList from "../views.transfers/transfers-list";


// views - user
import UserDetails from "../views.user/user-details";


// // views - auth
import AuthMailCode from "../views.auth/auth-mail-code";
import AuthNext from "../views.auth/auth-next";
import AuthSessionX from "../views.auth/auth-session-x";


// // views - onboard
import UserOnboardName from '../views.onboard/user-onboard-name'
import { InvoicePaymentSlipUpload } from "../content/invoices/invoice-paymentslip-upload";




const routes = [

  { route:'/', content: <Main />, auth:false },

  // user
  { route:'/user/home', content: <HomeUser />, auth:true },
  { route:'/team/home', content: <HomeUser />, auth:true },


  // invoices
  { route:'/user/invoices', content: <InvoicesList />, auth:true },
  { route:'/user/invoices/view/:id', content: <InvoicesView />, auth:true },
  { route:'/user/invoices/create', content: <InvoicesCreate />, auth:true },
  { route:'/upload/payment/slip/:id', content: <InvoicePaymentSlipUpload />, auth:false },
  

  { route:'/team/invoices', content: <InvoicesList />, auth:true },
  { route:'/team/invoices/view/:id', content: <InvoicesView />, auth:true },
  { route:'/team/invoices/create', content: <InvoicesCreate />, auth:true },

  // transfers
  { route:'/user/transfers', content: <TransfersList />, auth:true },
  { route:'/team/transfers', content: <TransfersList />, auth:true },


  // user
  { route:'/user/account', content: <UserDetails />, auth:true },
  { route:'/team/account', content: <UserDetails />, auth:true },

  // auth
  { route:'/auth', content: <AuthMailCode />, auth: false },
  { route:'/auth/next', content: <AuthNext />, auth: true },
  { route:'/auth/x', content: <AuthSessionX />, auth: true },
  
  // onboard
  { route:'/user/onboard', content: <UserOnboardName />, auth: true },


]


export default function RouteX() {

  const { user } = useAuth();
  // console.log (user)

  return (
    <Routes>
      {routes.map ((item,i)=>(item.auth
        ? <Route key={i} path={item.route} element={!user ? <Navigate to='/' replace /> : item.content} />
        : <Route key={i} path={item.route} element={item.content} />
      ))}
    </Routes>
  );
}