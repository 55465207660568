import axios from 'axios'
const base = 'https://us-east-1.aws.data.mongodb-api.com/app/proforma-paniit-qzbdk/endpoint'


// Function Calls

export const GetTemplateList = async item => {

  const basx = base + '/get/template'
  const head = {
    'Content-Type': 'application/json',
    'Authorization': process.env.REACT_APP_WEBB_SITE_CLNT
  }
  
  const datx = { data: item.data, srvc: process.env.REACT_APP_WEBB_SITE_SRVC }

  var result
  try {
    result = await axios.post(basx, datx, { headers: head })
    // console.log(result)
    return { data: { code: result.status, ...result.data } }
  } catch (error) {
    // console.log(error)
    return { data: { code: error.response.status, ...error.response.data } }
  }
}

export const CreateTask = async item => {

    const basx = base + '/create/task'
    const head = {
      'Content-Type': 'application/json',
      'Authorization': process.env.REACT_APP_WEBB_SITE_CLNT
    }
    
    const datx = { data: item.data, user: item.user, srvc: process.env.REACT_APP_WEBB_SITE_SRVC }
  
    var result
    try {
      result = await axios.post(basx, datx, { headers: head })
      // console.log(result)
      return { data: { code: result.status, ...result.data } }
    } catch (error) {
      // console.log(error)
      return { data: { code: error.response.status, ...error.response.data } }
    }
  }

  export const GetInvoiceList = async item => {

    const basx = base + '/invoice/list'
    const head = {
      'Content-Type': 'application/json',
      'Authorization': process.env.REACT_APP_WEBB_SITE_CLNT
    }
    
    const datx = { data: item.data, srvc: process.env.REACT_APP_WEBB_SITE_SRVC }
  
    var result
    try {
      result = await axios.post(basx, datx, { headers: head })
      // console.log(result)
      return { data: { code: result.status, ...result.data } }
    } catch (error) {
      // console.log(error)
      return { data: { code: error.response.status, ...error.response.data } }
    }
  }

  export const SendInvoiceAlert = async item => {

    const basx = base + '/send/alert'
    const head = {
      'Content-Type': 'application/json',
      'Authorization': process.env.REACT_APP_WEBB_SITE_CLNT
    }
    
    const datx = { data: item.data, srvc: process.env.REACT_APP_WEBB_SITE_SRVC }
  
    var result
    try {
      result = await axios.post(basx, datx, { headers: head })
      // console.log(result)
      return { data: { code: result.status, ...result.data } }
    } catch (error) {
      // console.log(error)
      return { data: { code: error.response.status, ...error.response.data } }
    }
  }

  export const GetInvoiceDetails = async item => {

    const basx = base + '/invoice/details'
    const head = {
      'Content-Type': 'application/json',
      'Authorization': process.env.REACT_APP_WEBB_SITE_CLNT
    }
    
    const datx = { data: item.data, srvc: process.env.REACT_APP_WEBB_SITE_SRVC }
  
    var result
    try {
      result = await axios.post(basx, datx, { headers: head })
      // console.log(result)
      return { data: { code: result.status, ...result.data } }
    } catch (error) {
      // console.log(error)
      return { data: { code: error.response.status, ...error.response.data } }
    }
  }

  export const SavePaymentSlip = async item => {

    const basx = base + '/save/paymentslip'
    const head = {
      'Content-Type': 'application/json',
      'Authorization': process.env.REACT_APP_WEBB_SITE_CLNT
    }
    
    const datx = { data: item.data, srvc: process.env.REACT_APP_WEBB_SITE_SRVC }
  
    var result
    try {
      result = await axios.post(basx, datx, { headers: head })
      // console.log(result)
      return { data: { code: result.status, ...result.data } }
    } catch (error) {
      // console.log(error)
      return { data: { code: error.response.status, ...error.response.data } }
    }
  }

  export const GetDashboardStat = async item => {

    const basx = base + '/dashboard/statistics'
    const head = {
      'Content-Type': 'application/json',
      'Authorization': process.env.REACT_APP_WEBB_SITE_CLNT
    }
    
    const datx = { data: item.data, srvc: process.env.REACT_APP_WEBB_SITE_SRVC }
  
    var result
    try {
      result = await axios.post(basx, datx, { headers: head })
      // console.log(result)
      return { data: { code: result.status, ...result.data } }
    } catch (error) {
      // console.log(error)
      return { data: { code: error.response.status, ...error.response.data } }
    }
  }

  export const InvoicePaymentSlip = async item => {

    const basx = base + '/invoice/payment/slips'
    const head = {
      'Content-Type': 'application/json',
      'Authorization': process.env.REACT_APP_WEBB_SITE_CLNT
    }
    
    const datx = { data: item.data, srvc: process.env.REACT_APP_WEBB_SITE_SRVC }
  
    var result
    try {
      result = await axios.post(basx, datx, { headers: head })
      // console.log(result)
      return { data: { code: result.status, ...result.data } }
    } catch (error) {
      // console.log(error)
      return { data: { code: error.response.status, ...error.response.data } }
    }
  }

  export const GetPaymentList = async item => {

    const basx = base + '/payment/list'
    const head = {
      'Content-Type': 'application/json',
      'Authorization': process.env.REACT_APP_WEBB_SITE_CLNT
    }
    
    const datx = { data: item.data, srvc: process.env.REACT_APP_WEBB_SITE_SRVC }
  
    var result
    try {
      result = await axios.post(basx, datx, { headers: head })
      // console.log(result)
      return { data: { code: result.status, ...result.data } }
    } catch (error) {
      // console.log(error)
      return { data: { code: error.response.status, ...error.response.data } }
    }
  }

  export const SendReminder = async item => {

    const basx = base + '/reminder/invoice/payment'
    const head = {
      'Content-Type': 'application/json',
      'Authorization': process.env.REACT_APP_WEBB_SITE_CLNT
    }
    
    const datx = { data: item.data, srvc: process.env.REACT_APP_WEBB_SITE_SRVC }
  
    var result
    try {
      result = await axios.post(basx, datx, { headers: head })
      // console.log(result)
      return { data: { code: result.status, ...result.data } }
    } catch (error) {
      // console.log(error)
      return { data: { code: error.response.status, ...error.response.data } }
    }
  }

  export const ApproveInvoice = async item => {

    const basx = base + '/invoice/approve'
    const head = {
      'Content-Type': 'application/json',
      'Authorization': process.env.REACT_APP_WEBB_SITE_CLNT
    }
    
    const datx = { data: item.data, srvc: process.env.REACT_APP_WEBB_SITE_SRVC }
  
    var result
    try {
      result = await axios.post(basx, datx, { headers: head })
      // console.log(result)
      return { data: { code: result.status, ...result.data } }
    } catch (error) {
      // console.log(error)
      return { data: { code: error.response.status, ...error.response.data } }
    }
  }