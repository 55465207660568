import React, { useState, useRef, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { GetInvoiceDetails, SavePaymentSlip } from '../../services/srvc-invoices-realm';
import WebbLoaderMedium from '../webx/webb-loader-md';

export const  InvoicePaymentSlipUpload =()=> {

  const {id} = useParams()
  const navigate = useNavigate()

  const [dragging, setDragging] = useState(false);
  const dropRef = useRef(null);
  const [file, setFile] = useState(null)
  const [loading, setLoading] = useState(true)
  const [data, setData] = useState()
  const [success, setSuccess] = useState(false)
 

  useEffect(()=>{
    setLoading(true)
    const fetchdata = async()=>{
        
        var res = (await GetInvoiceDetails({data:{item:id}})).data
        // console.log(res)
        setData(res)
        setLoading(false)
    }
    fetchdata()

  },[])

  const handleDragIn = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDragOut = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragging(false);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragging(true);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragging(false);

    let files = e.dataTransfer.files;
    if (files && files.length > 0) {
      handleFiles(files);
    }
  };

  const handleFiles = async (files) => {
    // Here, you can handle the file(s), like sending them to a server
    setFile(files[0])
    
  };

  const toBase64 = file => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = reject;
});

const SaveFile = async()=>{
    setLoading(true)
    if(!file)
    {
        window.alert("All fields are Mandatory")
        return
    }
    else{
        var base64 = await toBase64(file)
        // console.log(base64);
        var datx ={
            user:{},
            file: base64,
            invc: id,
            crus: data.crus
        }

        var res = await SavePaymentSlip({data:datx})
        if(res.data.stat){
            setSuccess(true)
        }
    }
    setLoading(false)
}

if(loading) return(<div className='text-center fw-bold text-secondary mt-5'>
  <p> Please Wait ..</p>
  <WebbLoaderMedium />
  </div>)
if(!loading && success) return(
    <div>
        <h6 className='fw-bold text-success mt-5 text-center'>File Successfully Saved</h6>
        <p className='small text-secondary mt-3 text-center'>You can close this window</p>
    </div>
)

  return (
    <div className='mt-5' style={{maxWidth:'500px', marginLeft:'auto', marginRight:'auto'}}>
    <div className=''>
        <img src='https://performa-paniit.s3.ap-south-1.amazonaws.com/media/mail-banner.png' className='image-fluid'  style={{width:'500px'}}/>
    </div>
    <h5 className='fw-bold text-secondary mt-3 mb-3'>Invoice Details </h5>
    <div className='border p-3 rounded text-small mb-4 bg-white '>
        <div className='d-flex'>
            <div className='me-auto'>Invoice Number: </div>
            <div className="text-end">{data.info.invoice_no}</div>
        </div>

        <div className='d-flex mt-2'>
            <div className='me-auto'>Company Name: </div>
            <div className="text-end">{data.info.company_name}</div>
        </div>

        <div className='d-flex mt-2'>
            <div className='me-auto'>GST Number: </div>
            <div className="text-end">{data.info.buyer_gst}</div>
        </div>

        <div className='d-flex mt-2'>
            <div className='me-auto'>Download Invoice File: </div>
            <div onClick={()=>window.open(data.file.link)} className="text-end  btn-link" style={{cursor:'pointer'}}>
                click here
            </div>
        </div>
       

    </div>

    {/* <h5 className='fw-bold text-secondary mt-3 mb-3'>User Details </h5> */}
    <div className='border p-3 rounded text-small mb-4'>
        {/* <label className=' small'>Company Name *</label>
        <input value={company} onChange={(e)=>setCompany(e.target.value)} className='form-control mb-2' />

        <label className=' small'>Recepient Name *</label>
        <input value={username} onChange={(e)=>setUsername(e.target.value)} className='form-control mb-2' />

        <label className=' small'>Recepient Mail *</label>
        <input value={usermail} onChange={(e)=>setUsermail(e.target.value)} className='form-control mb-2' /> */}
        
        <label className=' small'>Upload File *</label>
        <div
        ref={dropRef}
        onDragEnter={handleDragIn}
        onDragLeave={handleDragOut}
        onDragOver={handleDragOver}
        onDrop={handleDrop}
        style={{
            border: '2px dashed gray',
            height: '90px',
            width: '465px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            background: dragging ? 'lightyellow' : 'white',
        }}
        >
      {dragging ? (
        <div>Drop your files here</div>
      ) : (
        <>
          <div>Drag & drop your files or</div>
          <input
            type="file"
            onChange={(e) => handleFiles(e.target.files)}
            style={{ display: 'none' }}
            id="fileInput"
          />
          <label htmlFor="fileInput" style={{ cursor: 'pointer', marginLeft:'10px', color:'blue' }}>
            Browse
          </label>
        </>
      )}
        </div>

        {
        file ?
        <>
        <div>
            <p className='mt-2 m-0 fw-normal text-dark'>File Selected: <span className='text-primary'>{file.name}</span></p>
            <p className='fw-normal text-dark'>File Size: <span className='text-primary'>{file.size}KB</span></p>
        </div>
        </>:
        <></>
      }

        <button disabled={file==null?true:false} onClick={()=>SaveFile()} className='btn btn-success w-100 mt-3'>Submit</button>

    </div>

   
    </div>
  );
}

