import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { GetLocalBusiness, GetLocalUser } from '../../services/srvc-auth-user'
import { GetInvoiceList, SendInvoiceAlert } from '../../services/srvc-invoices-realm'
import WebbLoaderSmall from '../webx/webb-loader-sm'

export const InvoicePendings = (props) => {

const navigate = useNavigate()
const asset = GetLocalUser()
const team = GetLocalBusiness()
const [data, setData] = useState([])
const [loading, setLoading] = useState(true)
const [fresh, setFresh] = useState(false)


useEffect(()=>{
    if(asset)
    {

    setLoading(true)
    const fetchdata = async()=>{


      const res = (await GetInvoiceList({data:{user:asset.item}})).data
      //console.log(res)
      var list = res.data.list.filter(item=> item.alrt.sent==false)
      setData(list)
      
      
      setLoading(false)
    }
    fetchdata()
  }
},[fresh])

const SendAlert = async(item)=>{
    setLoading(true)
    console.log(item)

    const res = await SendInvoiceAlert({data:{item:item}})
    console.log(res)
    if(res.data){
      props.handlerefresh()
      setFresh(!fresh)
      // navigate("/user/invoices")
    }

    setLoading(false)
  }

  if(loading) return(<><WebbLoaderSmall /></>)
  if(data.length == 0) return(<>No Records Found</>)

  return (
    <div className='' >
       
        {
          data && data.map((item, i)=>{
            return(
              <div className='border p-3 rounded  bg-white'>
                <div className='d-flex'>
                  <div className='me-auto'>
                    <p className='text-dark fw-bold m-0'>COMPANY: <span  className='fw-normal'>{item.info.company_name}</span></p>
                    <p className='text-dark fw-bold m-0'>BUYER: <span className='fw-normal'>{item.mmbr.name}</span></p>
                  </div>
                  <div className='text-end'>
                  <button onClick={()=>SendAlert(item.item)} className={` ${item.alrt.sent? 'd-none':'btn  btn-primary rounded-xx mt-2 text-small'}`}>Send Invoice</button>
                  </div>
                
                </div>
              
              </div>

            )
          })
        }

      </div>
  )
}
